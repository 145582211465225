<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="companyGrid"
          ref="companyGridRef"
          :data-source="dataSource"
          height="calc(100vh - 150px)"
          :cache-enabled="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGird base components -->
            <dx-sorting mode="single" />
            <dx-selection
              show-check-boxes-mode="always"
              select-all-mode="page"
              :allow-select-all="true"
              :width="10"
              mode="multiple"
            />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
          <!--endregion ::DataGird base components -->
          <!--region    ::DataGird actions -->
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="140" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="success"
                icon="bi bi-toggles"
                class="mr-half"
                hint="Change Company Status"
                :visible="!ifTenentPSH"
                @click="onClickSetActivated(data)"
              />
              <dx-util-button
                type="warning"
                icon="bi bi-pencil-square"
                class="mr-half"
                hint="Edit Company Info"
                @click="openCompanyEdit(data)"
              />
              <dx-util-button
                type="default"
                icon="bi bi-bar-chart-line-fill"
                hint="Assign Plan"
                :visible="!ifTenentPSH"
                @click="onClickAssignPlan(data)"
              />
            </div>
          </template>
          <!--endregion ::DataGird actions -->
          <!--region    ::DataGird columns -->
          <dx-column data-field="tenantId" :visible="ifTenentPSH" alignment="left" />
          <dx-column data-field="accountNo" alignment="left" cell-template="accountNoCellTemplate" />
          <dx-column data-field="suiteNo" :visible="!ifTenentPSH" alignment="left" cell-template="accountNoCellTemplate" />
          <template #accountNoCellTemplate="{ data }">
            <router-link :to="{name: 'route-account-my-company', params: { companyId: data.data.id }}">
              {{ data.value }}
            </router-link>
          </template>
          <dx-column data-field="name" cell-template="nameCellTemplate" />
          <template #nameCellTemplate="{ data }">
            <router-link :to="{name: 'route-account-my-company', params: { companyId: data.data.id }}">
              {{ data.value }}
            </router-link>
          </template>
          <dx-column data-field="companyOwnerName" caption="Owner Name" :allow-sorting="false" />
          <dx-column data-field="companyOwnerEmail" caption="Owner Email" :allow-sorting="false" />
          <dx-column :visible="!ifTenentPSH" data-field="planAssigned" cell-template="planCell" caption="Plan Assigned" alignment="center" :allow-sorting="false" />
          <template #planCell="{data}">
            <div>
              <div v-if="checkPlan(data.value)">
                <span class="badge" :class="`badge-info`">{{ data.value }}</span>
              </div>
              <div v-if="!checkPlan(data.value)">
                <dx-util-button type="light-success" :element-attr="assignBtnOptions" text="Assign" hint="Assign new plan" @click="onClickAssignPlan(data)" />
              </div>
            </div>
          </template>
          <dx-column data-field="status" :customize-text="normalizeText" alignment="center" cell-template="statusCellTemplate" />
          <template #statusCellTemplate="{ data }">
            <span class="badge" :class="`badge-${statusStyle(data.value)}`">{{ data.value | capitalize }}</span>
          </template>
          <dx-column data-field="companyType" :customize-text="normalizeText" alignment="center" cell-template="companyTypeCellTemplate" />
          <template #companyTypeCellTemplate="{ data }">
            <span class="badge" :class="`badge-${companyTypeStyle(data.value)}`">{{ data.value | capitalize }}</span>
          </template>
          <dx-column data-field="creationTime" caption="Open Date" data-type="date" />
          <dx-column data-field="notes" :width="300" />
          <!--endregion ::DataGird columns -->
          <!--region    ::DataGrid toolbar -->
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model="filters.accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  id="companyTypeSelectBox"
                  v-model="filters.type"
                  :data-source="companyTypeOptions"
                  display-expr="name"
                  value-expr="id"
                  @value-changed="searchByFilter"
                />
              </div>
              <div>
                <dx-util-select-box
                  id="statusSelectBox"
                  v-model="filters.status"
                  :data-source="statusOptions"
                  display-expr="name"
                  value-expr="id"
                  @value-changed="searchByFilter"
                />
            </div>
          </div>
            </template>
          <!--endregion    ::DataGrid toolbar -->
        </dx-data-grid>
      </div>
      <div class="col-md-12 px-0">
      <!--Begin:: Create Or Update Form-->
        <dx-util-popup
          ref="popupChangeStatusRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="480"
          :height="300"
          title="Change Company Status"
          @hidden="onHiddenStatusChange"
        >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonChangeStatusOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonChangeStatusOptions" />
        <form @submit="handleSubmitChangeStatus">
          <dx-util-form ref="companyChangeStatusFormRef" :form-data="statusUpdateForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="companyData">
            <dx-util-item data-field="name" editor-type="dxTextBox" :editor-options="formNameOptions" />
              <dx-util-item data-field="status" editor-type="dxSelectBox" :editor-options="formStatusOptions">
                  <dx-util-required-rule message="Status is required" />
              </dx-util-item>
          </dx-util-form>
        </form>
        </dx-util-popup>
       <!--End:: -->
    </div>
    <div class="col-md-12 px-0">
    <dx-util-popup
      ref="popupAssignPlanRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="true"
      content-template="popup-content"
      :title="`${companyName}: Assign Plan`"
      @showing="onShowingAssignPlanPopup"
      @hidden="onHiddenAssignPlanPopup"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <assign-plan v-if="showAssignPlan" :company-id="companyId" @on-assigned-plan="onAssignedPlan" />
      </template>
    </dx-util-popup>
    <invitation-link :component-id="invitationComponentId" />
    <company-edit :component-id="companyEditCompId" :company-id="selectedCompanyId" @update-company="searchByFilter" />
    </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

import GridBase from '@core/dev-extreme/mixins/grid/base'
import companyService from '@/http/requests/company/companyService'
import useCurrentUser from '@/libs/app/current-user'
import CompanySearchFilter from '@/http/models/search-filters/CompanySearchFilter'
import { Notify } from '@robustshell/utils/index'
import { v4 as uuidv4 } from 'uuid'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { companyDataSource } from './companyStore'
import AssignPlan from './AssignPlan.vue'

export default {
  components: {
    'assign-plan': AssignPlan,
    'invitation-link': () => import('./InvitationLink.vue'),
    'company-edit': () => import('./components/CompanyEdit.vue'),
  },
  mixins: [GridBase],
  setup() {
    const {
      userTenantId,
      userTenantType,
    } = useCurrentUser()
    return {
      userTenantType,
      userTenantId,
    }
  },
  data() {
    return {
      dataSource: companyDataSource,
      companyEditCompId: '',
      companyId: 0,
      filters: {
        accountNo: '',
        type: '',
        status: '',
        query: '',
      },
      companyTypeOptions: [
        { name: 'Type (All)', id: '' },
        { name: 'Client', id: 'customer' },
        { name: 'Warehouse', id: 'warehouse' },
        // { name: 'Franchise', id: 'franchise' },
        { name: 'Vendor', id: 'vendor' },
      ],
      selectedStatus: '',
      statusOptions: [
        { name: 'Status (All)', id: '' },
        { name: 'Active', id: 'active' },
        { name: 'Passive', id: 'passive' },
        { name: 'Pending', id: 'pending' },
      ],
      statusUpdateForm: {
        id: null,
        status: '',
        name: '',
      },
      assignBtnOptions: {
        class: 'btn-assign',
      },
      showAssignPlan: false,
      companyName: '',
      invitationComponentId: '',
      selectedCompanyId: 0,
    }
  },
  computed: {
    ifTenentPSH() {
      return this.userTenantId === 'PSH'
    },
    dataGrid() {
      const grid = this.$refs.companyGridRef.instance
      return grid
    },
    hasPermission() {
      return this.$can('read', 'resource_company_list_admin_action')
    },
    isServingTenant() {
      return this.userTenantType === 'SERVING'
    },
    saveButtonChangeStatusOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmitChangeStatus(e.event)
        },
      }
    },
    cancelButtonChangeStatusOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.$refs.popupChangeStatusRef.instance.hide()
        },
      }
    },
    formNameOptions() {
      return {
        readOnly: true,
      }
    },
    formStatusOptions() {
      return {
        dataSource: this.statusOptions,
        valueExpr: 'id',
        displayExpr: 'name',
        searchEnabled: true,
        dropDownOptions: {
          height: 150,
        },
      }
    },
    statusQ() {
      return this.$route.query.status
    },
  },
  watch: {
    'companyForm.id'(newValue) {
      if (newValue) {
        this.getCalendarByCompanyId(newValue)
      }
    },
  },
  mounted() {
    if (this.statusQ) {
      this.filters.status = this.statusQ
    }
    this.initialLoad()
  },
  methods: {
    checkPlan(e) {
      const val = e
      return (val && val !== '' && typeof val !== 'undefined' && e !== null)
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    async onDownloadReport(e) {
      const filters = CompanySearchFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await companyService.findByQuery(filters, pageableQuery)
      const data = response.data.content
      this.onDownloadExportData(data)
    },
    onDownloadReportSelected() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Clients_${fileDate}`)
      worksheet.columns = [
        { header: 'Account No', key: 'accountNo', width: 10 },
        { header: 'Suite No', key: 'suiteNo', width: 10 },
        { header: 'Name', key: 'name', width: 20 },
        { header: 'Owner Name', key: 'companyOwnerName', width: 20 },
        { header: 'Owner Email', key: 'companyOwnerEmail', width: 20 },
        { header: 'Status', key: 'status', width: 14 },
        { header: 'Company Type', key: 'companyType', width: 14 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.accountNo,
          item.suiteNo,
          item.name,
          item.companyOwnerName,
          item.companyOwnerEmail,
          item.status,
          item.companyType,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Clients_${fileDate}.xlsx`,
        )
      })
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Report',
          onItemClick: arg => {
            if (arg) {
              //
            }
            if (arg.itemData.id === 'all') {
              this.onDownloadReport()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadReportSelected()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Incomplete Users',
          icon: 'user',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => {
            this.$router.push({ name: 'users-list' })
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          visible: this.isServingTenant,
          text: 'Invite',
          icon: 'icon bi-share',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => {
            this.invitationComponentId = uuidv4()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.selectedStatus = ''
            this.filters.type = ''
            this.filters.accountNo = ''
            this.searchByFilter()
          },
        },
        location: 'after',
      })
    },
    initialLoad() {
      CompanySearchFilter.setDefaultFilters()
      CompanySearchFilter.accountNo = this.filters.accountNo
      CompanySearchFilter.type = this.filters.type
      CompanySearchFilter.status = this.filters.status
      CompanySearchFilter.q = this.filters.query
      const filters = CompanySearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    normalizeText(cellInfo) {
      return this.$options.filters.capitalize(cellInfo.valueText)
    },
    statusStyle(status) {
      if (status === 'active') return 'success'
      if (status === 'passive') return 'secondary'
      if (status === 'deactivated') return 'secondary'
      if (status === 'suspended') return 'danger'
      if (status === 'not_complete') return 'warning'
      if (status === 'deleted') return 'secondary'
      return 'primary'
    },
    companyTypeStyle(type) {
      if (type === 'customer') return 'success'
      if (type === 'warehouse') return 'primary'
      if (type === 'franchise') return 'info'
      if (type === 'vendor') return 'warning'
      return 'primary'
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.filters.accountNo = e.event.target.value.toString()
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.filters.accountNo = ''
        this.searchByFilter()
      }
    },
    searchByFilter(e) {
      if (e && e.element.id === 'companyTypeSelectBox') this.filters.type = e.value
      if (e && e.element.id === 'statusSelectBox') this.filters.status = e.value
      CompanySearchFilter.type = this.filters.type
      CompanySearchFilter.status = this.filters.status
      CompanySearchFilter.accountNo = this.filters.accountNo
      CompanySearchFilter.q = this.filters.query
      const filter = CompanySearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filter)
      dataSource.reload()
    },
    onClickSetActivated(e) {
      const currentCompany = e.data
      this.statusUpdateForm.id = currentCompany.id
      this.statusUpdateForm.name = currentCompany.name
      this.statusUpdateForm.status = currentCompany.status

      const popup = this.$refs.popupChangeStatusRef.instance
      popup.show()
    },
    async handleSubmitChangeStatus() {
      await companyService.activate(this.statusUpdateForm.id, this.statusUpdateForm.status)
      const popup = this.$refs.popupChangeStatusRef.instance
      popup.hide()
      this.searchByFilter()
    },
    onHiddenStatusChange() {
      this.statusUpdateForm.id = ''
      this.statusUpdateForm.name = ''
      this.statusUpdateForm.status = ''
    },
    openCompanyEdit(data) {
      this.selectedCompanyId = data.data.id
      this.companyEditCompId = uuidv4()
    },
    onShowingAssignPlanPopup() {
      this.showAssignPlan = true
    },
    onHiddenAssignPlanPopup() {
      this.showAssignPlan = false
    },
    onClickAssignPlan(row) {
      this.companyId = row.data.id
      this.companyName = row.data.name
      const popup = this.$refs.popupAssignPlanRef.instance
      popup.show()
    },
    onAssignedPlan() {
      const popup = this.$refs.popupAssignPlanRef.instance
      popup.hide()
      const filters = CompanySearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
  },
}
</script>
<style lang="scss">
  .btn-assign{
    border-color: rgba(33, 125, 187, 0.5);
    color: rgba(33, 125, 187, 1) !important;
    width: 100% !important;
    .dx-button-content {
      padding: 4px 4px 4px !important;
    }
  }
</style>

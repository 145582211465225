import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import companyService from '@/http/requests/company/companyService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'accountNo', desc: true }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.addSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}${pager.toCreateSortable()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null

    return companyService
      .findByQuery(filters, pageableQuery)
      .then(response => {
        const data = response.data
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
})

const companyDataSource = new DataSource({
  store: store,
})

export {
  companyDataSource,
}

/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class CompanySearchFilter extends BaseSearchFilter {
  /** @type {?number} */
  #type = 0
  /** @returns {?number} */
  get type() {
    return this.#type
  }
  /** @param {?number} value */
  set type(value) {
    this.#type = value
  }

  /** @type {!string} */
  #status = 'active'
  /** @returns {string} */
  get status() {
    return this.#status
  }
  /** @param {!string} value */
  set status(value) {
    this.#status = value
  }
  constructor() {
    super()
    this.#type = this.type
    this.#status = this.status
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.type = 0
    this.status = 'active'
  }

  /**
   * The complete PlanSearchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {string} type - Company Type.
   * @property {string} status - Status.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      type: this.type,
      status: this.status,
    }
    return filter
  }
}
export default new CompanySearchFilter()

<template>
      <div>
        <dx-data-grid
          id="assignPlanGrid"
          ref="assignPlanGridRef"
          :height="setHeight"
          :data-source="assignPlanDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @selection-changed="onSelectionChanged"
          @content-ready="onContentReady"
          @exporting="onExporting"
          @toolbar-preparing="onToolbarPreparing($event)"
        >
          <dx-sorting mode="single" />
          <dx-remote-operations
            :paging="true"
            :sorting="false"
            :filtering="false"
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="onClick"
            :allow-select-all="false"
            :width="10"
            mode="single"
          />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-column data-field="planCategory" width="auto" :customize-text="customizePlanCategoryText" />
          <dx-column data-field="planType" />
          <dx-column data-field="planName" />
          <dx-column data-field="itemFee" width="auto" cell-template="itemFeeTemplate" />
          <template #itemFeeTemplate="{ data }">
            {{ getCurrencyFormat(data) }}
          </template>
          <dx-column data-field="minItemCount" caption="Min Count" width="auto" />
          <dx-column data-field="maxItemCount" caption="Max Count" width="auto" />
          <dx-column data-field="discount" width="auto" :customize-text="customizeDiscountText" />
          <dx-column data-field="status" width="auto" cell-template="statusCellTemplate" />
          <template #statusCellTemplate="{ data }">
            <span class="badge" :class="`badge-${statusStyle(data.value)}`">{{ data.value | capitalize }}</span>
          </template>
          <dx-column data-field="warehouseName" />
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedPlanType"
                  :data-source="planTypes"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedPlanCategory"
                  :data-source="planCategories"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-number-box v-model="planEnrollment.discount" :max="100">
                    <dx-text-box-button
                      location="before"
                      name="prevButtonDiscount"
                      :options="prevButton"
                    />
                  </dx-util-number-box>
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
</template>

<script>
import { Notify } from '@robustshell/utils/index'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import { custom } from 'devextreme/ui/dialog'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import planManagementService from '@/http/requests/plan/planManagementService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import { currencyFormatter } from '@core/utils/filter'
import { assignPlanDataSource } from './assignPlanStore'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  mixins: [GridBase, Filters],
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      assignPlanDataSource,
      prevButton: {
        text: 'Discount %:',
        disabled: true,
        elementAttr: {
          class: 'dx-button-prepend',
        },
      },
      blankForm: {
        companyId: 0,
        planId: 0,
        status: 'active',
        discount: 0,
        notes: '',
      },
      planEnrollment: {},
      planTypes: [],
      selectedPlanType: 0,
      selectedPlanId: null,
      planCategories: [
        { text: 'Plan Category', value: 'all' },
        { text: 'FBA', value: 'fba' },
        { text: 'WFS', value: 'wfs' },
        { text: 'FBM', value: 'fbm' },
        { text: 'Storage', value: 'storage' },
        { text: 'Forwarding', value: 'forwarding' },
        { text: 'DropShip', value: 'dropship' },
        { text: 'B2B', value: 'b2b' },
      ],
      selectedPlanCategory: 'all',
    }
  },
  computed: {
    dataGrid() {
      const gridIns = this.$refs.assignPlanGridRef.instance
      return gridIns
    },
  },
  mounted() {
    this.planEnrollment = JSON.parse(JSON.stringify(this.blankForm))
    this.selectedPlanId = null
    this.initialLoad()
    this.getWarehouses()
    this.getPlanTypes()
  },
  destroyed() {
    this.planEnrollment = JSON.parse(JSON.stringify(this.blankForm))
    this.dataGrid.clearSelection()
  },
  setHeight() {
    return window.innerHeight / 1.1
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Save',
          type: 'success',
          onClick: () => {
            this.onSubmit()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
    },
    initialLoad() {
      PlanSearchFilter.setDefaultFilters()
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      PlanSearchFilter.setDefaultFilters()
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      this.dataGrid.clearSelection()
      this.assignPlanDataSource.reload()
    },
    searchByFilter() {
      this.dataGrid.clearSelection()
      PlanSearchFilter.warehouseId = this.selectedWarehouse
      PlanSearchFilter.planType = this.selectedPlanType
      PlanSearchFilter.planCategory = this.selectedPlanCategory
      const filters = PlanSearchFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data.value)
    },
    customizeDiscountText(cellInfo) {
      return `${cellInfo.value} %`
    },
    customizePlanCategoryText(cellInfo) {
      return `${cellInfo.value.toUpperCase()}`
    },
    statusStyle(status) {
      if (status.toLowerCase() === 'active') return 'success'
      if (status.toLowerCase() === 'passive') return 'secondary'
      if (status.toLowerCase() === 'pending') return 'warning'
      if (status.toLowerCase() === 'rejected') return 'danger'
      return 'primary'
    },
    onSelectionChanged({ selectedRowsData }) {
      if (selectedRowsData.length > 0) {
        const selectedPlan = { ...selectedRowsData[0] }
        this.selectedPlanId = selectedPlan.id
      } else {
        this.selectedPlanId = null
      }
    },
    getPlanTypes() {
      this.planTypes.splice(0, this.planTypes.length)
      this.planTypes.push({ value: 0, text: 'Plan Type (All)' })
      planManagementService.fetchAllPlanTypes().then(result => {
        result.data.forEach(element => {
          this.planTypes.push({ value: element.id, text: element.typeName })
        })
      })
    },
    onSubmit() {
      if (this.selectedPlanId) {
        const askAuthorizeDialog = custom({
          title: 'Plan Assignment',
          messageHtml: '<b>Are you sure you want to enroll the selected company to this Plan?</b>',
          buttons: [
            {
              text: 'Confirm',
              type: 'default',
              stylingMode: 'contained',
              elementAttr: { class: 'float-left' },
              value: true,
              onClick: arg => ({ result: arg.component.option('value') }),
            },
            {
              text: 'Cancel',
              type: 'danger',
              stylingMode: 'contained',
              value: false,
              onClick: arg => ({ result: arg.component.option('value') }),
            },
          ],
        })
        askAuthorizeDialog.show().then(async dialogResult => {
          if (dialogResult.result) {
            this.planEnrollment.planId = this.selectedPlanId
            this.planEnrollment.companyId = this.companyId
            planManagementService
              .createPlanEnrollment(this.planEnrollment)
              .then(() => {
                Notify.success('You have been enrolled the selected company to this plan.', 'Enrolled!')
                this.planEnrollment = JSON.parse(JSON.stringify(this.blankForm))
                this.dataGrid.clearSelection()
                this.$emit('on-assigned-plan')
              })
          }
        })
      } else {
        Notify.warning('Please select a plan.')
      }
    },
  },
}
</script>
<style lang="scss">
.dx-button-prepend{
  border-radius: 0px !important;
  border: none;
  padding: 0 0 !important;
}
</style>
